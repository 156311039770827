/* You can add global styles to this file, and also import other style files */
:root {
  --dark-background: #202030;
  --light-background: #fbfbfb;
  --paragraphs: #000000;
  --title-pink: #ff66b3;
  --secondary-text: #c4c4c4;
  --link: #ff66b3;
  --inactive-link: #c4c4c4;
  --button-background-pink: #ff66b3;
  --button-background-dark: #202030;
  --button-link: #fbfbfb;
}

/* Text */
.mat-typography {
  font-family: "Roboto", sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Bebas Neue", cursive;
  }

  .font-r {
    font-family: "Roboto", sans-serif;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Sidenav */
.sidenav {
  width: 100vw;
  background-color: var(--title-pink);
}

/* Buttons */
.mat-button.mat-pink,
.mat-stroked-button.mat-pink {
  color: #ff66b3;
}

.mat-button.mat-moss,
.mat-stroked-button.mat-moss {
  color: var(--dark-background);
}
.mat-button.mat-pink:hover,
.mat-stroked-button.mat-pink:hover,
.mat-button.mat-pink:hover,
.mat-stroked-button.mat-pink:hover {
  background-color: #f0fff3;
}

.mat-raised-button.mat-pink,
.mat-flat-button.mat-pink,
.mat-fab.mat-pink,
.mat-mini-fab.mat-pink {
  color: #f0fff3;
  background-color: #ff66b3;

  &.rounded {
    border-radius: 2rem;
  }
}

.mat-raised-button.mat-moss,
.mat-flat-button.mat-moss,
.mat-fab.mat-moss,
.mat-mini-fab.mat-moss {
  color: #f0fff3;
  background-color: var(--dark-background);
}

.mat-icon-button.mat-pink {
  color: #ff66b3;
}

/* landing specific */
#newsletter {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #fff !important;
  }
}

/* Menu specific */
.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.mat-icon-button {
  &#menu-button {
    color: #fff;
    padding-right: 1vmin;
    font-size: 24px;
    text-align-last: right;
  }
}

.mat-button-focus-overlay {
  display: none;
}
